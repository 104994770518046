<template>
  <div class="verify-banner">
    <div class="verify-banner__inner">
      <v-icon class="verify-banner__icon" color="primary">
        mdi-shield-alert-outline
      </v-icon>

      <h1 class="verify-banner__title">Верифікуйте аккаунт</h1>

      <p class="verify-banner__description">
        На вашу електронну пошту
        <code>{{ email }}</code>
        надіслано листа з посиланням на підтвердження
      </p>

      <div class="verify-banner__actions">
        <v-btn
          class="mb-3"
          elevation="0"
          color="primary"
          block
          :small="$vuetify.breakpoint.smAndDown"
          :loading="refreshLoading"
          @click="onRefresh"
        >
          <v-icon left> mdi-refresh </v-icon>
          Оновити дані
        </v-btn>

        <v-btn
          elevation="0"
          color="primary"
          text
          block
          :small="$vuetify.breakpoint.smAndDown"
          :loading="resendLoading"
          @click="resendDialog = true"
        >
          <v-icon left> mdi-email-send-outline </v-icon>
          Надіслати повторно
        </v-btn>
      </div>
    </div>

    <confirm-dialog
      v-model="resendDialog"
      title="Надіслати посилання повторно ? "
      @confirm="onResend"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/common/ConfirmDialog";
import showNotification from "@/helpers/show-notification";

export default {
  components: {
    ConfirmDialog,
  },
  data: () => ({
    refreshLoading: false,
    resendLoading: false,
    resendDialog: false,
  }),
  computed: {
    email() {
      return this.$store.state.auth.user.email;
    },
  },
  methods: {
    async onRefresh() {
      this.refreshLoading = true;
      await this.$store.dispatch("auth/loginByToken");
      this.refreshLoading = false;
    },
    async onResend() {
      this.resendDialog = false;
      this.resendLoading = true;

      const { error } = await this.$store.dispatch(
        "auth/resendVerificationLink"
      );

      this.resendLoading = false;
      showNotification({ error, message: "Лист надіслано" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.verify-banner {
  padding: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    max-width: 500px;
    text-align: center;
  }

  &__icon {
    margin-bottom: 20px;
    font-size: 80px;

    @media (max-width: $md) {
      font-size: 60px;
      margin-bottom: 10px;
    }
  }

  &__title {
    font-size: 30px;
    margin-bottom: 10px;

    @media (max-width: $md) {
      font-size: 22px;
      margin-bottom: 5px;
    }
  }

  &__description {
    margin-bottom: 40px;

    @media (max-width: $md) {
      font-size: 14px;
      margin-bottom: 25px;
    }
  }

  &__actions {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $md) {
      max-width: 250px;
    }
  }
}
</style>

<template>
  <v-menu nudge-top="-5" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar v-bind="attrs" v-on="on" size="40" color="secondary">
        <span class="white--text">{{ avatarText }}</span>
      </v-avatar>
    </template>

    <div class="profile-menu">
      <div class="profile-menu__header">
        <div class="profile-menu__name">
          {{ user.name }}
        </div>
        <small class="profile-menu__email">
          {{ user.email }}
        </small>
      </div>

      <ul class="profile-menu__links">
        <li>
          <v-btn
            color="primary"
            block
            text
            small
            @click="$store.commit('profile/setSettingsDialogOpened', true)"
          >
            <v-icon left small> mdi-cog </v-icon>
            Налаштування
          </v-btn>
        </li>

        <li v-if="isSuperAdmin">
          <v-btn
            color="primary"
            block
            text
            small
            @click="$store.commit('profile/setCreateAdminDialogOpened', true)"
          >
            <v-icon left small> mdi-account-plus </v-icon>
            Створити адміна
          </v-btn>
        </li>

        <li v-if="isAdmin || isSuperAdmin">
          <v-btn
            color="primary"
            block
            text
            small
            @click="$store.commit('orders/setUpdateRateDialogOpened', true)"
          >
            <v-icon left small> mdi-sync </v-icon>
            Змінити курс
          </v-btn>
        </li>
      </ul>

      <v-divider class="mt-2 mb-2" />

      <v-btn
        color="red"
        block
        text
        small
        @click="$store.dispatch('auth/logout')"
      >
        <v-icon class="mr-2" small> mdi-logout-variant </v-icon>
        Вийти
      </v-btn>
    </div>
  </v-menu>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    isSuperAdmin() {
      return this.$store.getters["auth/isSuperAdmin"];
    },
    avatarText() {
      const { name } = this.user;
      return name[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-menu {
  min-width: 150px;
  padding: 10px;
  color: #777;
  background-color: #fff;

  &__name {
    line-height: 1;
    font-size: 15px;
  }

  &__email {
    line-height: 1;
  }

  &__links {
    margin-top: 5px;
    padding: 0;

    li:not(:last-child) {
      margin-bottom: 2px;
    }
  }
}

.v-menu__content {
  border-radius: 10px;
  overflow: hidden !important;
}
</style>

<template>
  <div class="auth-form">
    <h1 class="auth-form__title">
      {{ title }}
    </h1>

    <p class="auth-form__subtitle">
      {{ subtitle }}
    </p>

    <v-tabs-items :value="value" touchless>
      <v-tab-item>
        <forgot-pass-form />
      </v-tab-item>

      <v-tab-item>
        <login-form />
      </v-tab-item>

      <v-tab-item>
        <register-form />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ForgotPassForm from "@/components/auth/ForgotPassForm";
import LoginForm from "@/components/auth/LoginForm";
import RegisterForm from "@/components/auth/RegisterForm";

export default {
  components: { LoginForm, RegisterForm, ForgotPassForm },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.auth-form {
  padding: 10px 20px 20px;
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: $lg) {
    max-width: 400px;
  }

  &__title {
    font-size: 32px;

    @media (max-width: $md) {
      font-size: 26px;
    }
  }

  &__subtitle {
    font-size: 16px;
    margin-bottom: 25px;

    @media (max-width: $md) {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
}
</style>

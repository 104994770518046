export default {
  1: `Внести валюту можна у обмінному пункті за адресою м.Київ вул. Антоновича, 165 (Фуршет) з приміткою "за авто аукціон"<br>
  Комісія: 100$ + 2.5% (від суми інвойсу)
  <br>
  <a href="https://maps.app.goo.gl/jHnc2SrLCrUEPXyg6" style="margin-top:5px;font-style:normal;text-decoration:underline" target="_blank">На карті</a>`,
  2: `Внести гривню можна у обмінному пункті за адресою м.Київ вул. Антоновича, 165 (Фуршет) з приміткою "за авто аукціон"<br>
  Комісія: 100$ + 2.5% (від суми інвойсу) + курс долара на поточний день
  <br>
  <a href="https://maps.app.goo.gl/jHnc2SrLCrUEPXyg6" style="margin-top:5px;font-style:normal;text-decoration:underline" target="_blank">На карті</a>`,
  3: `Поповніть наш Tether USDT крипто-рахунок після чого введіть HASH транзакції на другому кроці оформлення даної заявки.<br>
  Комісія: 100 USDT + 1%  (від суми інвойсу)`,
};

<template>
  <default-dialog v-model="opened" title="Налаштування профілю">
    <v-form
      class="profile-settings__form"
      :disabled="loading"
      @submit.prevent="onSubmit"
    >
      <v-text-field
        v-model="formData.name"
        label="ПІБ"
        dense
        outlined
        :rules="[isRequired]"
      />
      <v-text-field
        v-model="formData.email"
        label="Електронна пошта"
        dense
        outlined
        :rules="[isRequired, isEmail]"
        :error-messages="validationErrors.email"
        @input="validationErrors.email = []"
      />

      <v-btn
        type="submit"
        color="primary"
        elevation="0"
        dense
        block
        :loading="loading"
      >
        Зберегти
      </v-btn>
    </v-form>
  </default-dialog>
</template>

<script>
import DefaultDialog from "@/components/common/DefaultDialog";
import { isRequired, isEmail } from "@/helpers/validation-rules";

export default {
  components: { DefaultDialog },
  data: () => ({
    loading: false,

    formData: {
      name: "",
      email: "",
    },

    validationErrors: {
      email: [],
    },
  }),
  watch: {
    opened(value) {
      if (value) {
        this.formData.name = this.user.name;
        this.formData.email = this.user.email;
      } else {
        this.loading = false;
      }
    },
  },
  computed: {
    opened: {
      get() {
        return this.$store.state.profile.settingsDialogOpened;
      },

      set(val) {
        this.$store.commit("profile/setSettingsDialogOpened", val);
      },
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    isRequired,
    isEmail,
    async onSubmit() {
      this.loading = true;

      const { success, validationErrors } = await this.$store.dispatch(
        "profile/updateUser",
        this.formData
      );

      if (success) this.opened = false;
      if (validationErrors) this.validationErrors = validationErrors;

      this.loading = false;
    },
  },
};
</script>

import axios from "axios";
import store from "@/store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

instance.interceptors.response.use(null, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    store.commit("auth/setIsAuthorized", false);
  }

  return Promise.reject(error);
});

store.$axios = instance;

<template>
  <v-form
    ref="register"
    class="register-form"
    :disabled="loading"
    @submit.prevent="onSubmit"
  >
    <fieldset class="register-form__fields">
      <v-text-field
        v-model="email"
        label="Електронна пошта"
        outlined
        :rules="[isRequired, isEmail]"
        :dense="dense || isMobile"
        :error-messages="validationErrors.email"
        @input="validationErrors.email = []"
      />
      <v-text-field
        v-model="password"
        autocomplete="new-password"
        type="password"
        label="Пароль"
        outlined
        :rules="[isRequired, isPassword, isPasswordsSame]"
        :dense="dense || isMobile"
        :error-messages="validationErrors.password"
        @input="validationErrors.password = []"
      />
      <v-text-field
        v-model="repeatPassword"
        type="password"
        label="Повторіть пароль"
        outlined
        :rules="[isRequired, isPasswordsSame]"
        :dense="dense || isMobile"
      />
      <v-text-field
        v-model="name"
        label="ПІБ"
        outlined
        :rules="[isRequired]"
        :dense="dense || isMobile"
      />
    </fieldset>

    <slot name="submit-button" :loading="loading">
      <v-btn
        elevation="0"
        color="primary"
        class="register-form__submit"
        type="submit"
        block
        rounded
        :height="isMobile ? 40 : 50"
        :loading="loading"
      >
        Зареєструватися
      </v-btn>
      <v-btn
        elevation="0"
        color="primary"
        class="register-form__login"
        type="submit"
        block
        rounded
        text
        to="/auth/login"
        :height="isMobile ? 40 : 50"
        :disabled="loading"
        @click="resetForm"
      >
        Вже маєте аккаунт ?
      </v-btn>
    </slot>
  </v-form>
</template>

<script>
import { isRequired, isEmail, isPassword } from "@/helpers/validation-rules";
import isMobile from "@/mixins/is-mobile";

export default {
  mixins: [isMobile],
  props: {
    actionName: {
      type: String,
      default: "auth/register",
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    validationErrors: {
      email: [],
      password: [],
    },

    email: "",
    password: "",
    repeatPassword: "",
    name: "",
  }),
  methods: {
    isRequired,
    isEmail,
    isPassword,
    isPasswordsSame() {
      const passEmpty = !this.password;
      const repeatPassEmpty = !this.repeatPassword;

      if (passEmpty || repeatPassEmpty) return true;

      return this.password === this.repeatPassword || "Паролі не збігаються";
    },
    resetForm() {
      this.email = "";
      this.password = "";
      this.repeatPassword = "";
      this.name = "";

      this.validationErrors = {
        email: [],
        password: [],
      };

      this.$refs.register.resetValidation();
    },
    async onSubmit() {
      const isValid = this.$refs.register.validate();

      if (!isValid) return;

      this.loading = true;

      const { success, validationErrors } = await this.$store.dispatch(
        this.actionName,
        {
          email: this.email,
          password: this.password,
          password_confirmation: this.repeatPassword,
          name: this.name,
        }
      );

      if (validationErrors) this.validationErrors = validationErrors;
      if (success) this.$emit("success");
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.register-form {
  &__fields {
    padding-top: 5px;
    overflow-y: auto;

    @media (max-width: $md) {
      max-height: 270px;
    }
  }

  &__submit {
    margin-top: 15px;
  }

  &__login {
    margin-top: 20px;

    @media (max-width: $md) {
      margin-top: 10px;
    }
  }
}
</style>

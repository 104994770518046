<template>
  <default-dialog v-model="opened" title="Змінити курс">
    <v-form ref="update-rate" @submit.prevent="onSubmit">
      <v-select
        v-model="formData.type"
        item-text="name"
        item-value="value"
        label="Тип оплати"
        dense
        outlined
        :items="availableTypes"
        :loading="typesLoading"
        :rules="[isRequired]"
      />
      <v-text-field
        v-model="formData.percent"
        type="number"
        label="Нове значення"
        prepend-inner-icon="mdi-percent"
        dense
        outlined
        :disabled="!formData.type"
        :rules="[isRequired]"
      />

      <v-btn
        type="submit"
        elevation="0"
        color="primary"
        block
        :loading="loading"
      >
        Зберегти
      </v-btn>
    </v-form>
  </default-dialog>
</template>

<script>
import DefaultDialog from "@/components/common/DefaultDialog.vue";
import { isRequired } from "@/helpers/validation-rules";

export default {
  components: { DefaultDialog },
  data: () => ({
    loading: false,

    formData: {
      type: "",
      percent: "",
    },
  }),
  watch: {
    opened(val) {
      if (!val) this.$refs["update-rate"].reset();
    },
  },
  computed: {
    opened: {
      get() {
        return this.$store.state.orders.updateRateDialogOpened;
      },
      set(val) {
        this.$store.commit("orders/setUpdateRateDialogOpened", val);
      },
    },
    types() {
      return this.$store.getters["orders/activeTypes"];
    },
    typesLoading() {
      return this.$store.state.orders.typesLoading;
    },
    availableTypes() {
      return this.types.filter(({ value }) => value !== 1);
    },
  },
  methods: {
    isRequired,
    async onSubmit() {
      const isValid = this.$refs["update-rate"].validate();

      if (!isValid) return;

      this.loading = true;

      const { success } = await this.$store.dispatch("orders/updateRate", {
        type: this.formData.type,
        percent: Number(this.formData.percent).toFixed(2),
      });

      if (success) {
        this.opened = false;
        this.$store.dispatch("notifications/add", {
          text: "Курс змінено",
          type: "success",
        });
      }

      this.loading = false;
    },
  },
};
</script>

<style></style>

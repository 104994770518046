<template>
  <default-dialog v-model="opened" title="Завантажити файл оплати">
    <v-form ref="upload-doc" @submit.prevent="onSubmit">
      <v-file-input
        v-model="file"
        accept="application/pdf, image/jpg"
        label="JPG або PDF"
        dense
        outlined
        :prepend-icon="null"
        :rules="[isRequired]"
      />

      <v-btn
        type="submit"
        elevation="0"
        color="primary"
        block
        :loading="loading"
      >
        Зберегти
      </v-btn>
    </v-form>
  </default-dialog>
</template>

<script>
import DefaultDialog from "@/components/common/DefaultDialog";
import { isRequired } from "@/helpers/validation-rules";

export default {
  components: { DefaultDialog },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    file: null,
  }),
  watch: {
    opened(val) {
      if (!val) this.$refs["upload-doc"].reset();
    },
  },
  computed: {
    opened: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  methods: {
    isRequired,
    async onSubmit() {
      const isValid = this.$refs["upload-doc"].validate();

      if (!isValid) return;

      this.loading = true;

      const formData = new FormData();
      formData.append("file_admin", this.file);

      const { success } = await this.$store.dispatch(
        "orders/uploadPaymentDocument",
        {
          id: this.orderId,
          formData,
        }
      );

      if (success) {
        this.opened = false;
        this.$emit("success");
      }

      this.loading = false;
    },
  },
};
</script>

<template>
  <v-form
    ref="login-form"
    class="login-form"
    :disabled="loading"
    @submit.prevent="onSubmit"
  >
    <v-text-field
      v-model="email"
      class="login-form__email"
      label="Електронна пошта"
      outlined
      :dense="isMobile"
      :rules="[isRequired, isEmail]"
      :error-messages="validationErrors.email"
      @input="validationErrors.email = []"
    />
    <v-text-field
      v-model="password"
      label="Пароль"
      type="password"
      outlined
      hide-details
      :dense="isMobile"
      :rules="[isRequired]"
    />

    <button
      class="login-form__forgot-pass"
      type="button"
      :disabled="loading"
      @click="
        () => {
          $router.push('/auth/forgot-password');
          resetForm();
        }
      "
    >
      Забули пароль ?
    </button>
    <v-btn
      elevation="0"
      color="primary"
      class="login-form__enter"
      type="submit"
      block
      rounded
      :height="isMobile ? 40 : 50"
      :loading="loading"
    >
      Увійти
    </v-btn>
    <v-btn
      elevation="0"
      color="primary"
      class="login-form__register"
      type="submit"
      block
      rounded
      text
      to="/auth/register"
      :height="isMobile ? 40 : 50"
      :disabled="loading"
      @click="resetForm"
    >
      Немає аккаунту ?
    </v-btn>
  </v-form>
</template>

<script>
import { isRequired, isEmail } from "@/helpers/validation-rules";
import isMobile from "@/mixins/is-mobile";

export default {
  mixins: [isMobile],
  data: () => ({
    loading: false,
    validationErrors: {
      email: [],
    },

    email: "",
    password: "",
  }),
  methods: {
    isRequired,
    isEmail,
    resetForm() {
      this.email = "";
      this.password = "";

      this.validationErrors = {
        email: [],
      };

      this.$refs["login-form"].resetValidation();
    },
    async onSubmit() {
      const isValid = this.$refs["login-form"].validate();

      if (!isValid) return;

      this.loading = true;

      const { validationErrors } = await this.$store.dispatch("auth/login", {
        email: this.email,
        password: this.password,
      });

      if (validationErrors) this.validationErrors = validationErrors;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.login-form {
  padding-top: 5px;

  &__email {
    ::v-deep .v-input__slot {
      margin-bottom: 4px;

      @media (max-width: $md) {
        margin-bottom: 3px;
      }
    }

    ::v-deep .v-text-field__details {
      margin-bottom: 7px;

      @media (max-width: $md) {
        margin-bottom: 4px;
      }
    }
  }

  &__forgot-pass {
    font-size: 14px;
    margin: 13px 3px 5px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  &__enter {
    margin-top: 20px;

    @media (max-width: $md) {
      margin-top: 15px;
    }
  }

  &__register {
    margin-top: 20px;

    @media (max-width: $md) {
      margin-top: 10px;
    }
  }
}
</style>

import AuthPage from "@/views/AuthPage";
import ProfilePage from "@/views/ProfilePage";

export default [
  // Profile
  {
    name: "profile",
    path: "/",
    component: ProfilePage,
    meta: { requireAuth: true },
  },
  // Auth
  {
    name: "login",
    path: "/auth/login",
    component: AuthPage,
    meta: { requireNonAuth: true },
  },
  {
    name: "register",
    path: "/auth/register",
    component: AuthPage,
    meta: { requireNonAuth: true },
  },
  {
    name: "forgot-password",
    path: "/auth/forgot-password",
    component: AuthPage,
    meta: { requireNonAuth: true },
  },
  //Expired verification
  {
    name: "expired-verification",
    path: "/expired-verification-link",
    component: () => import("@/views/ExpiredVerificationPage.vue"),
  },
  // Reset password
  {
    name: "password-reset",
    path: "/password-reset/:token",
    component: () => import("@/views/ResetPasswordPage"),
    meta: { requireNonAuth: true },
  },
  // *
  { path: "*", redirect: "/" },
];

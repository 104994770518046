<template>
  <transition-group name="notifications" class="notifications" tag="div">
    <v-alert
      v-for="item in list"
      class="notifications__item"
      elevation="4"
      border="left"
      prominent
      dense
      colored-border
      :key="item.id"
      :type="item.type"
    >
      {{ item.text }}
    </v-alert>
  </transition-group>
</template>

<script>
export default {
  watch: {
    list(newValue, oldValue) {
      if (newValue.length > this.maxCount) {
        this.$store.dispatch("notifications/deleteLast");
      }

      if (newValue.length > oldValue.length) {
        setTimeout(() => {
          this.$store.dispatch("notifications/deleteLast");
        }, 3000);
      }
    },
  },
  computed: {
    list() {
      return this.$store.state.notifications.list;
    },
    maxCount() {
      return this.$vuetify.breakpoint.smAndDown ? 2 : 4;
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 350px;
  width: 100%;
  transition: all 0.3s;
  z-index: 1000;

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: scale(0);
  }

  &-leave-active {
    opacity: 0;
    transform: none;
  }

  &__item {
    margin: 8px;
    transition: all 0.3s;
    line-height: 1.3;
  }
}
</style>

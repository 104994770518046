<template>
  <default-dialog v-model="opened" max-width="500" title="Як сплатити ?">
    <div class="order-instructions__subtitle">
      Зробити оплату можливо декількома способами:
    </div>

    <div class="order-instructions__content">
      <v-progress-circular
        v-if="$store.state.orders.typesLoading"
        width="3"
        size="30"
        color="secondary"
        indeterminate
      />

      <ul v-else class="order-instructions__list">
        <li v-for="item in types" :key="item.value">
          <b>{{ item.name }}</b>
          <p v-html="typesDescription[item.value]" />
        </li>
      </ul>
    </div>
  </default-dialog>
</template>

<script>
import DefaultDialog from "@/components/common/DefaultDialog";
import typesDescription from "@/constants/typesDescription";

export default {
  components: { DefaultDialog },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
  },
  data: () => ({
    typesDescription,
  }),
  computed: {
    opened: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    types() {
      return this.$store.getters["orders/activeTypes"];
    },
  },
};
</script>

<style lang="scss">
.order-instructions {
  &__subtitle {
    margin-bottom: 10px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    border-radius: 10px;
    padding: 10px;
    background-color: #eee;
  }

  &__list {
    color: #5b5b5b;
    padding-left: 0 !important;

    li:not(:last-child) {
      padding-bottom: 10px;
      border-bottom: 1px dashed #5b5b5b;
    }

    li:not(:first-child) {
      padding-top: 8px;
    }

    b {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
}
</style>

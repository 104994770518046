<template>
  <default-dialog
    v-model="opened"
    class="add-order"
    title="Створити заявку"
    max-width="550"
  >
    <v-form ref="add-order" @submit.prevent="onSubmit">
      <v-item-group v-model="formData.type" class="add-order__types">
        <div class="add-order__types-title">Оберіть тип оплати:</div>

        <div
          v-if="$store.state.orders.typesLoading"
          class="add-order__types-loader"
        >
          <v-progress-circular
            width="3"
            size="30"
            color="secondary"
            indeterminate
          />
        </div>

        <template v-else>
          <v-item
            v-for="(type, key) in types"
            :key="key"
            :value="type.value"
            v-slot="{ active, toggle }"
          >
            <div
              v-ripple="{ class: 'primary--text' }"
              class="add-order__type"
              :class="{ active }"
              @click="toggle"
            >
              <b>{{ type.name }}</b>
              <p v-html="typesDescription[type.value]" />
            </div>
          </v-item>
        </template>
      </v-item-group>

      <v-file-input
        v-model="formData.file"
        label="Завантажте інвойс (JPG або PDF)"
        accept="application/pdf, image/jpg"
        dense
        outlined
        :disabled="!formData.type"
        :prepend-icon="null"
        :rules="[isRequired]"
      />

      <v-text-field
        v-model.number="formData.sum"
        label="Сума в інвойсі"
        type="number"
        dense
        outlined
        :disabled="!formData.type"
        :rules="[isRequired]"
      />

      <v-text-field
        prepend-inner-icon="mdi-equal"
        label="Кінцева сума з нашою комісією"
        dense
        outlined
        readonly
        :disabled="!formData.type"
        :value="total"
        :loading="totalLoading"
      >
        <template #append>
          <v-progress-circular
            v-if="formulaLoading"
            size="22"
            width="2"
            color="blue"
            indeterminate
          />
          <v-tooltip v-else-if="formula" color="blue" left max-width="300">
            <template #activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="blue"
                style="cursor: pointer"
              >
                mdi-information-slab-circle-outline
              </v-icon>
            </template>

            {{ formula }}
          </v-tooltip>
        </template>
      </v-text-field>

      <v-text-field
        v-model="formData.note"
        label="Код примітка (якщо наявний)"
        dense
        outlined
        :disabled="!formData.type"
      />

      <v-btn
        type="submit"
        color="primary"
        elevation="0"
        block
        :loading="loading"
      >
        Створити платіж
      </v-btn>
    </v-form>
  </default-dialog>
</template>

<script>
import DefaultDialog from "@/components/common/DefaultDialog";
import typesDescription from "@/constants/typesDescription";
import { isRequired } from "@/helpers/validation-rules";

export default {
  components: { DefaultDialog },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    totalLoading: false,
    total: 0,
    formulaLoading: false,
    formula: null,

    formData: {
      type: null,
      file: null,
      sum: "",
      note: "",
    },

    typesDescription,
  }),
  watch: {
    opened(val) {
      if (!val) this.resetForm();
    },
    "formData.type"(val) {
      if (!val) {
        this.resetForm();
        return;
      }

      this.fetchFormulaByType({ type: val, sum: this.formData.sum });
    },
    "formData.sum"(val) {
      this.fetchFormulaByType({ type: this.formData.type, sum: val });
    },
    totalRequestParams: {
      deep: true,
      handler({ sum, type }) {
        if (sum && type) this.fetchTotal({ sum, type });
        if (!sum) this.total = 0;
      },
    },
  },
  computed: {
    opened: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("change", val);
      },
    },
    types() {
      return this.$store.getters["orders/activeTypes"];
    },
    totalRequestParams() {
      return {
        sum: this.formData.sum,
        type: this.formData.type,
      };
    },
  },
  methods: {
    isRequired,
    async fetchTotal({ type, sum }) {
      this.totalLoading = true;

      this.total = await this.$store.dispatch("orders/fetchTotal", {
        type,
        sum: sum.toFixed(2),
      });

      this.totalLoading = false;
    },
    async fetchFormulaByType({ type, sum }) {
      if (!sum || !type) return;

      this.formulaLoading = true;

      const { formula } = await this.$store.dispatch(
        "orders/fetchFormulaByType",
        {
          type,
          sum: sum.toFixed(2),
        }
      );

      this.formula = type === 1 ? `${formula} USDT` : `${formula} курс USDT`;
      this.formulaLoading = false;
    },
    async onSubmit() {
      const isValid = this.$refs["add-order"].validate();

      if (!isValid) return;

      const formData = new FormData();
      formData.append("type", this.formData.type);
      formData.append("file", this.formData.file);
      formData.append("sum", this.formData.sum.toFixed(2));
      formData.append("note", this.formData.note);

      this.loading = true;
      const { success } = await this.$store.dispatch(
        "orders/createItem",
        formData
      );
      this.loading = false;

      if (success) {
        this.opened = false;
        this.$emit("updated");
        this.$store.dispatch("notifications/add", {
          text: "Заявку збережено",
          type: "success",
        });
      }
    },
    resetForm() {
      this.formData.type = null;
      this.formData.file = null;
      this.formData.sum = "";
      this.total = 0;
      this.formula = null;
      this.$refs["add-order"].resetValidation();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.add-order {
  &__types {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: #eee;

    &-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.6);
    }

    &-loader {
      padding-top: 5px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__type {
    position: relative;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px 5px 30px;
    border: 1.3px dashed rgba(0, 0, 0, 0.54);
    border-radius: 5px;
    background-color: #fff;
    transition: 0.2s;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 8px;
      left: 7px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1.3px dashed rgba(0, 0, 0, 0.6);
    }

    &.active {
      background-color: lighten($primary, 50%);
      border-color: $primary;
      border-style: solid;
      color: rgba(0, 0, 0, 0.7);

      &::after {
        background-color: lighten($primary, 20%);
        border-style: solid;
        border-color: $primary;
      }
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    b {
      display: block;
      font-size: 15px;
      font-weight: 500;
    }

    p {
      font-size: 13px;
      font-style: italic;

      @media (max-width: $sm) {
        display: none;
      }
    }
  }
}
</style>

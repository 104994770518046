<template>
  <default-dialog v-model="opened" title="Створити адміна">
    <register-form
      v-if="formOpened"
      action-name="profile/createAdmin"
      dense
      @success="onSuccess"
    >
      <template #submit-button="{ loading }">
        <v-btn
          type="submit"
          color="primary"
          elevation="0"
          block
          :loading="loading"
        >
          Зберегти
        </v-btn>
      </template>
    </register-form>
  </default-dialog>
</template>

<script>
import DefaultDialog from "@/components/common/DefaultDialog";
import RegisterForm from "@/components/auth/RegisterForm";

export default {
  components: { DefaultDialog, RegisterForm },
  data: () => ({
    formOpened: false,
  }),
  watch: {
    opened(val) {
      if (val) this.formOpened = true;
      else setTimeout(() => (this.formOpened = false), 300);
    },
  },
  computed: {
    opened: {
      get() {
        return this.$store.state.profile.createAdminDialogOpened;
      },
      set(val) {
        this.$store.commit("profile/setCreateAdminDialogOpened", val);
      },
    },
  },
  methods: {
    onSuccess() {
      this.$store.dispatch("notifications/add", {
        text: `Обліковий запис успішно створено`,
        type: "success",
      });
      this.opened = false;
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="opened"
    content-class="default-dialog"
    persistent
    :max-width="maxWidth"
  >
    <h3 class="default-dialog__title">{{ title }}</h3>

    <v-btn
      class="default-dialog__close-btn"
      color="secondary"
      icon
      small
      @click="opened = false"
    >
      <v-icon> mdi-close-circle-outline </v-icon>
    </v-btn>

    <slot />
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: [String, Number],
      default: 350,
    },
  },
  computed: {
    opened: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.default-dialog {
  position: relative;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 95% !important;
  background-color: #fff;

  &__title {
    color: $secondary;
    margin-bottom: 15px;
  }

  &__close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>

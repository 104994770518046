import handleError from "@/helpers/handle-error";

const state = () => ({
  typesLoading: false,
  types: [],
  statuses: [],
  list: [],
  updateRateDialogOpened: false,
});

const getters = {
  activeTypes(state) {
    return state.types.filter(({ value }) => value !== 2);
  },
};

const actions = {
  async fetchList({ rootGetters, commit }, params) {
    try {
      let method;
      let route;

      if (rootGetters["auth/isUser"]) {
        method = "get";
        route = "orders/user-orders";
      }

      if (rootGetters["auth/isAdmin"] || rootGetters["auth/isSuperAdmin"]) {
        method = "post";
        route = "orders/admin-orders";
      }

      const { data } = await this.$axios[method](route, params);
      commit("setList", data);
    } catch (error) {
      return error;
    }
  },

  async createItem(_, formData) {
    try {
      await this.$axios.post("orders/store", formData);
      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  async updateItem({ state, commit }, { id, formData }) {
    try {
      await this.$axios.post(`orders/update/${id}`, formData);

      const { list } = state;
      const updatedList = list.map((item) => {
        let order = item;

        if (item.id === id) {
          order = { ...order, ...formData };
        }

        return order;
      });

      commit("setList", updatedList);
      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  async fetchTypes({ commit }) {
    try {
      commit("setTypesLoading", true);

      const { data } = await this.$axios.get("orders/types");

      const types = Object.entries(data).map(([value, name]) => ({
        name,
        value: Number(value),
      }));

      commit("setTypes", types);
    } catch (error) {
      return handleError(error);
    } finally {
      commit("setTypesLoading", false);
    }
  },

  async fetchTotal(_, formData) {
    try {
      const { data } = await this.$axios.post("orders/total", formData);
      return data.total;
    } catch (error) {
      return handleError(error);
    }
  },

  async fetchStatuses({ commit }) {
    try {
      const { data } = await this.$axios.get("orders/statuses");

      const statuses = Object.entries(data).map(([value, name]) => ({
        name,
        value: Number(value),
      }));

      commit("setStatuses", statuses);
    } catch (error) {
      return error;
    }
  },

  async uploadPaymentDocument(_, { id, formData }) {
    try {
      await this.$axios.post(`orders/update-admin/${id}`, formData);
      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  async updateRate(_, { type, percent }) {
    try {
      await this.$axios.post(`percents/update/${type}`, { percent });
      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  async fetchFormulaByType(_, { type, sum }) {
    try {
      const { data } = await this.$axios.post("orders/formula", {
        type,
        sum,
      });

      return data;
    } catch (error) {
      return handleError(error);
    }
  },

  async denyOrder(_, id) {
    try {
      await this.$axios.post(`orders/deny-order/${id}`);
      return { success: true };
    } catch (error) {
      return error;
    }
  },
};

const mutations = {
  setTypesLoading(state, payload) {
    state.typesLoading = payload;
  },

  setTypes(state, payload) {
    state.types = payload;
  },

  setStatuses(state, payload) {
    state.statuses = payload;
  },

  setList(state, payload) {
    state.list = payload;
  },

  setUpdateRateDialogOpened(state, payload) {
    state.updateRateDialogOpened = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="order-code">
    <a
      v-if="isUrl(code)"
      class="order-code__link"
      :href="code"
      target="_blank"
      outlined
      small
    >
      <v-icon size="16"> mdi-link-variant </v-icon>
      Хеш
    </a>
    <span v-else class="order-code__number">
      {{ code }}
    </span>
  </div>
</template>

<script>
import isUrl from "@/helpers/is-url";

export default {
  props: {
    code: {
      type: [String, null],
    },
  },
  methods: {
    isUrl,
  },
};
</script>

<style lang="scss">
.order-code {
  &__number,
  &__link {
    height: 27px;
    background-color: #eee;
    padding: 3px 10px;
    font-size: 14px;
    color: #777 !important;
    border-radius: 5px;
    border: 1px solid #777;
  }

  &__number {
    display: inline-block;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    color: #55b0f5 !important;
    border: 1px solid #55b0f5;
    background-color: #eef6fc;

    .v-icon {
      margin-right: 5px;
      color: #55b0f5 !important;
    }
  }
}
</style>

import handleError from "@/helpers/handle-error";

const state = () => ({
  isAuthorized: Boolean(localStorage.getItem("token")),
  user: {
    name: "",
    email: "",
  },
});

const getters = {
  isVerified(state) {
    const verifiedAt = state.user.email_verified_at;
    return Boolean(verifiedAt);
  },

  isUser(state) {
    return state.user.role === 1;
  },

  isAdmin(state) {
    return state.user.role === 2;
  },

  isSuperAdmin(state) {
    return state.user.role === 3;
  },
};

const actions = {
  async register({ commit }, formData) {
    try {
      const { data } = await this.$axios.post("auth/register", formData);

      localStorage.setItem("token", data.access_token);
      commit("setUser", data.user);
      commit("setIsAuthorized", true);

      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  async login({ commit }, formData) {
    try {
      const { data } = await this.$axios.post("auth/login", formData);

      localStorage.setItem("token", data.access_token);
      commit("setUser", data.user);
      commit("setIsAuthorized", true);

      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  async loginByToken({ commit }) {
    try {
      const { data } = await this.$axios.get("auth/me");
      commit("setUser", data);
    } catch (error) {
      return error;
    }
  },

  async resendVerificationLink() {
    try {
      await this.$axios.post("auth/email/verification-notification");
      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  async forgotPassword(_, formData) {
    try {
      await this.$axios.post("auth/forgot-password", formData);

      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  async resetPassword(_, formData) {
    try {
      await this.$axios.post("auth/reset-password", formData);
      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  logout({ commit }) {
    localStorage.removeItem("token");
    commit("setIsAuthorized", false);
  },
};

const mutations = {
  setIsAuthorized(state, payload) {
    state.isAuthorized = payload;
  },

  setIsVerified(state, payload) {
    state.isVerified = payload;
  },

  setUser(state, payload) {
    state.user = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="rate-widget">
    <v-icon class="rate-widget__icon" color="#fff"> mdi-label-percent </v-icon>

    <span class="rate-widget__title"> Курс USDT: </span>

    <div class="rate-widget__value">
      <v-expand-x-transition>
        <v-progress-circular v-if="loading" size="16" width="2" indeterminate />
        <span v-else>{{ rate }}</span>
      </v-expand-x-transition>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
  }),
  computed: {
    rate() {
      return this.$store.state.profile.rate;
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("profile/fetchRate");
    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.rate-widget {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border: 2px solid #fff;
  border-radius: 30px;
  font-weight: 500;
  color: #fff;

  @media (max-width: $md) {
    padding: 3px 10px;
  }

  &__icon {
    margin-right: 10px;
  }

  &__title {
    margin-right: 10px;
    @media (max-width: $md) {
      display: none;
    }
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

import isUrlString from "./is-url";

export const isRequired = (val) => Boolean(val) || "Обов'язкове поле";

export const isEmail = (val) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return pattern.test(val.trim()) || "Некоректна ел. пошта";
};

export const isPassword = (val) => {
  const regex = new RegExp(/^.*(?=.{8,})(?=.*[a-zA-Z]).*$/g);
  const isValid = regex.test(val);

  return isValid || "Мінімум 8 символів, одна буква";
};

export const minLength = (length) => (val) => {
  return val.length >= length || `Мінімум ${length} символів`;
};

export const isUrl = (str) => {
  return isUrlString(str) || "Невалідне посилання";
};

const state = () => ({
  list: [],
});

const actions = {
  add({ state, commit }, { text, type = "info" }) {
    const list = [...state.list];
    const id = Math.random() * 10;

    list.unshift({ id, text, type });
    commit("setList", list);
  },

  deleteLast({ state, commit }) {
    const list = [...state.list];
    list.pop();

    commit("setList", list);
  },
};

const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

<template>
  <main class="profile">
    <header class="profile__header">
      <main-logo class="profile__logo" />

      <div class="d-flex">
        <rate-widget v-if="isAdmin || isSuperAdmin" class="mr-2" />
        <profile-menu />
      </div>
    </header>

    <div class="profile__content">
      <div class="profile__content-inner">
        <div v-if="loading" class="profile__content-loader">
          <v-progress-circular size="50" width="5" indeterminate />
        </div>
        <orders-table v-else-if="isVerified" />
        <verify-banner v-else />
      </div>
    </div>

    <profile-settings-dialog />
    <create-admin-dialog />
    <update-rate-dialog />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import MainLogo from "@/components/common/MainLogo";
import RateWidget from "@/components/profile/RateWidget";
import ProfileMenu from "@/components/profile/ProfileMenu";
import VerifyBanner from "@/components/profile/VerifyBanner";
import OrdersTable from "@/components/orders/OrdersTable";
import ProfileSettingsDialog from "@/components/profile/ProfileSettingsDialog";
import CreateAdminDialog from "@/components/profile/CreateAdminDialog";
import UpdateRateDialog from "@/components/orders/UpdateRateDialog.vue";

export default {
  components: {
    MainLogo,
    RateWidget,
    ProfileMenu,
    VerifyBanner,
    OrdersTable,
    ProfileSettingsDialog,
    CreateAdminDialog,
    UpdateRateDialog,
  },
  metaInfo: {
    title: "Мої заявки",
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      isVerified: "auth/isVerified",
      isAdmin: "auth/isAdmin",
      isSuperAdmin: "auth/isSuperAdmin",
    }),
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("auth/loginByToken");
    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.profile {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $accent;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
  }

  &__content {
    height: calc(100% - 70px);
    padding: 20px;

    &-inner {
      height: 100%;
      border-radius: 20px;
      background-color: #fff;
      overflow: hidden;
    }

    &-loader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: $md) {
      padding: 20px 0 0;

      &-inner {
        border-radius: 30px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &__logo {
    max-width: 120px;
  }
}
</style>

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    icons: {
      iconfont: "fa",
    },
    themes: {
      light: {
        primary: "#1ad08c",
        secondary: "#333333",
        accent: "#628ad3",
        error: "#b71c1c",
      },
      dark: {
        primary: "#333333",
        secondary: "#1ad08c",
        accent: "#628ad3",
        error: "#b71c1c",
      },
    },
  },
});

<template>
  <v-app>
    <router-view />
    <notifications-container />
  </v-app>
</template>

<script>
import NotificationsContainer from "@/components/notifications/NotificationsContainer";

export default {
  metaInfo: {
    titleTemplate: "%s | CarPay",
  },
  components: { NotificationsContainer },
  computed: {
    isAuthorized() {
      return this.$store.state.auth.isAuthorized;
    },
  },
  watch: {
    isAuthorized(value) {
      if (value) this.$router.replace("/");
      else this.$router.replace("/auth/login");
    },
  },
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const { requireAuth, requireNonAuth } = to.meta;
  const { isAuthorized } = store.state.auth;

  if (requireAuth && !isAuthorized) next({ name: "login" });
  else if (requireNonAuth && isAuthorized) next({ name: "profile" });
  else next();
});

export default router;

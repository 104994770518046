<template>
  <main :style="{ height: screenHeight }" class="auth">
    <header class="auth__header">
      <main-logo class="auth__header-logo" fill="white" />
    </header>

    <div class="auth__poster">
      <v-img
        class="auth__poster-img"
        :class="data.class"
        :src="data.poster"
        alt="Login"
        contain
      />
    </div>

    <div class="auth__content">
      <auth-tabs
        :title="data.title"
        :subtitle="data.subtitle"
        :value="data.value"
      />
    </div>
  </main>
</template>

<script>
import MainLogo from "@/components/common/MainLogo";
import AuthTabs from "@/components/auth/AuthTabs";
import isMobile from "@/mixins/is-mobile";

export default {
  mixins: [isMobile],
  metaInfo() {
    return {
      title: this.data.title,
    };
  },
  components: { MainLogo, AuthTabs },
  computed: {
    data() {
      const routeName = this.$route.name;

      switch (routeName) {
        case "forgot-password":
          return {
            class: "forgot-password",
            value: 0,
            poster: require("@/assets/images/illustrations/forgot-pass.svg"),
            title: "Відновити пароль",
            subtitle:
              "На вказану пошту прийде посилання для відновлення паролю",
          };

        case "register":
          return {
            class: "register",
            value: 2,
            poster: require("@/assets/images/illustrations/register.svg"),
            title: "Реєстрація",
            subtitle: "Заповніть дані для реєстрації в системі",
          };

        default:
          return {
            class: "login",
            value: 1,
            poster: require("@/assets/images/illustrations/login.svg"),
            title: "Авторизація",
            subtitle: "Введіть дані для входу в особистий кабінет",
          };
      }
    },
    screenHeight() {
      const height = document.documentElement.clientHeight;

      return this.isMobile ? `${height}px` : "100%";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.auth {
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $accent;

  @media (max-width: $md) {
    flex-direction: column;
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 1;

    &-logo {
      width: 120px;
    }
  }

  &__poster {
    width: 55%;
    align-self: center;
    display: flex;
    justify-content: center;

    &-img {
      &.login {
        max-width: 600px;
      }

      &.register {
        max-width: 550px;
      }

      &.forgot-password {
        max-width: 650px;
      }
    }

    @media (max-width: $lg) {
      &-img {
        &.login {
          max-width: 450px;
        }

        &.register {
          max-width: 400px;
        }

        &.forgot-password {
          max-width: 500px;
        }
      }
    }

    @media (max-width: $md) {
      width: 100%;
      height: 100%;

      &-img {
        &.login {
          max-width: 350px;
        }

        &.register {
          max-width: 250px;
        }

        &.forgot-password {
          max-width: 350px;
        }
      }
    }

    @media (max-width: $sm) {
      width: 100%;
      height: 100%;

      &-img {
        &.login {
          max-width: 170px;
        }

        &.register {
          max-width: 100px;
        }

        &.forgot-password {
          max-width: 270px;
        }
      }
    }
  }

  &__content {
    width: 45%;
    display: flex;
    align-items: center;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    @media (max-width: $md) {
      width: 100%;
      border-bottom-left-radius: 0;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    }
  }
}
</style>

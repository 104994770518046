<template>
  <v-form
    ref="restore-pass"
    class="restore-pass"
    :loading="loading"
    @submit.prevent="onSubmit"
  >
    <v-text-field
      v-model="email"
      class="restore-pass__email"
      label="Електронна пошта"
      outlined
      :dense="isMobile"
      :rules="[isRequired, isEmail]"
      :error-messages="validationErrors.email"
      @input="validationErrors.email = []"
    />

    <v-btn
      elevation="0"
      color="primary"
      class="restore-pass__submit"
      type="submit"
      block
      rounded
      :height="isMobile ? 40 : 50"
      :loading="loading"
    >
      Відправити
    </v-btn>
    <v-btn
      elevation="0"
      color="primary"
      class="restore-pass__back"
      type="submit"
      block
      rounded
      text
      to="/auth/login"
      :height="isMobile ? 40 : 50"
      @click="resetForm"
    >
      Назад
    </v-btn>
  </v-form>
</template>

<script>
import { isRequired, isEmail } from "@/helpers/validation-rules";
import showNotification from "@/helpers/show-notification";
import isMobile from "@/mixins/is-mobile";

export default {
  mixins: [isMobile],
  data: () => ({
    loading: false,
    email: "",

    validationErrors: {
      email: [],
    },
  }),
  methods: {
    isRequired,
    isEmail,
    resetForm() {
      this.email = "";
      this.validationErrors = {
        email: [],
      };

      this.$refs["restore-pass"].resetValidation();
    },
    async onSubmit() {
      const isValid = this.$refs["restore-pass"].validate();

      if (!isValid) return;

      this.loading = true;
      const { success, error, validationErrors } = await this.$store.dispatch(
        "auth/forgotPassword",
        {
          email: this.email,
        }
      );

      if (validationErrors) this.validationErrors = validationErrors;
      else showNotification({ error, message: "Посилання надісланно" });

      if (success) this.$refs["restore-pass"].reset();

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.restore-pass {
  padding-top: 5px;
  position: relative;

  &__back {
    margin-top: 15px;
  }

  &__success {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
  }

  @keyframes success {
  }
}
</style>

const formatNum = (num) => (num < 10 ? `0${num}` : num);

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = formatNum(date.getDate());
  const month = formatNum(date.getMonth());
  const year = date.getFullYear();
  const hour = formatNum(date.getHours());
  const minutes = formatNum(date.getMinutes());

  return `${day}.${month}.${year} ${hour}:${minutes}`;
};

export default formatDate;

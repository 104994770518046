import axios from "axios";

const createFileName = (path) => {
  const arr = path.split("/");
  return arr[arr.length - 1];
};

const downloadFile = async (path) => {
  const { data: file } = await axios.get(path, { responseType: "blob" });

  const xlsx = new Blob([file]);
  const url = window.URL.createObjectURL(xlsx);
  const link = document.createElement("a");

  link.setAttribute("download", createFileName(path));
  link.href = url;
  link.target = "_blank";
  link.style.display = "none";
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export default downloadFile;

<template>
  <v-select
    v-model="status"
    class="status-select"
    color="secondary"
    label="Статус"
    item-text="name"
    item-value="value"
    outlined
    dense
    rounded
    hide-details
    multiple
    clearable
    :items="statuses"
  >
    <template #selection="{ index }">
      <span v-if="index === 0"> {{ status.length }} обрано </span>
    </template>
  </v-select>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    status: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    statuses() {
      return this.$store.state.orders.statuses;
    },
  },
};
</script>

<style lang="scss">
.status-select {
  max-width: 200px;
  width: 100%;
}
</style>

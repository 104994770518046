<template>
  <v-dialog v-model="opened" content-class="confirm-dialog" max-width="350">
    <h3 class="confirm-dialog__title">
      {{ title }}
    </h3>

    <div class="d-flex justify-space-between">
      <v-btn
        class="confirm-dialog__btn"
        elevation="0"
        text
        @click="opened = false"
      >
        Відмінити
      </v-btn>

      <v-btn
        class="confirm-dialog__btn"
        elevation="0"
        color="success"
        @click="
          () => {
            $emit('confirm');
            this.opened = false;
          }
        "
      >
        Ок
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  model: {
    event: "change",
    prop: "value",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Ви впевнені ?",
    },
  },
  computed: {
    opened: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
};
</script>

<style lang="scss">
.confirm-dialog {
  padding: 15px 10px;
  background-color: #fff;

  &__title {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__btn {
    width: 140px;
  }
}
</style>

<template>
  <v-tooltip
    color="blue"
    v-bind="{ [position]: true }"
    content-class="formula-description"
  >
    <template #activator="{ attrs, on }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        color="blue"
        style="cursor: pointer"
        @mouseover="onHover"
      >
        mdi-information-slab-circle-outline
      </v-icon>
    </template>

    <div class="formula-description__content">
      <v-expand-x-transition>
        <v-progress-circular
          v-if="loading"
          color="#fff"
          size="20"
          width="2"
          indeterminate
        />
        <p v-else-if="formula" class="formula-description__formula">
          {{ formula }}
        </p>
      </v-expand-x-transition>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    sum: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    position: {
      type: String,
      default: "left",
    },
  },
  data: () => ({
    loading: false,
    formula: null,
  }),
  methods: {
    async onHover() {
      if (this.formula) return;

      this.loading = true;
      const { formula } = await this.$store.dispatch(
        "orders/fetchFormulaByType",
        {
          sum: this.sum,
          type: this.type,
        }
      );

      this.formula =
        this.type === 1 ? `${formula} USDT` : `${formula} курс USDT`;

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.formula-description {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
  }

  &__formula {
    white-space: nowrap;
    opacity: 0;
    animation-name: fade;
    animation-fill-mode: forwards;
    animation-delay: 300ms;
    animation-duration: 200ms;

    @keyframes fade {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
}
</style>

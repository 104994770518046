<template>
  <div class="orders">
    <div class="orders__header">
      <h1 class="orders__title">Список заявок</h1>

      <div class="orders__header-actions">
        <v-text-field
          v-model="noteSearch"
          class="orders__note-search"
          label="Код примітка"
          width="250"
          :prepend-inner-icon="isMobile ? null : 'mdi-magnify'"
          :append-icon="noteSearch ? 'mdi-close' : ''"
          dense
          outlined
          rounded
          hide-details
          @click:append="noteSearch = ''"
        />

        <status-select
          v-if="isAdmin || isSuperAdmin"
          v-model="filters.statuses"
          :disabled="statusesLoading"
        />

        <div v-if="isUser" class="d-flex">
          <v-btn
            height="40"
            elevation="0"
            color="secondary"
            class="orders__add-order-btn"
            rounded
            dark
            :fab="isMobile"
            @click="addOrderDialog = true"
          >
            <template v-if="!isMobile"> Створити заявку </template>
            <v-icon :size="isMobile ? '' : 20" :right="!isMobile">
              mdi-plus-circle-outline
            </v-icon>
          </v-btn>

          <v-btn
            height="40"
            elevation="0"
            color="secondary"
            rounded
            dark
            :outlined="!isMobile"
            :fab="isMobile"
            @click="orderInstructionsDialog = true"
          >
            <template v-if="!isMobile"> Як сплатити </template>
            <v-icon :right="!isMobile">mdi-help-circle-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <orders-instructions class="orders__steps" />

    <v-data-table
      class="orders__table"
      loading-text="Завантаження даних"
      no-data-text="Немає данних"
      locale="ru"
      :headers="headers"
      :header-props="{ 'sort-by-text': 'Сортувати за:' }"
      :loading="loading"
      :items="filteredList"
      :item-class="getTableRowClass"
      :items-per-page="-1"
      hide-default-footer
      @click:row.self="showCurrentStep"
    >
      <template #[`item.deny-order`]="{ item, isMobile }">
        <v-btn
          v-if="isDenyOrderBtnVisible(item.status)"
          elevation="0"
          color="red lighten-1"
          small
          rounded
          dark
          :icon="!isMobile"
          :loading="denyOrdersLoading.includes(item.id)"
          @click.stop="
            () => {
              confirmDialog.opened = true;
              confirmDialog.title = 'Відхилити заявку ?';
              confirmDialog.action = () => denyOrder(item.id);
            }
          "
        >
          <span v-if="isMobile">Відхилити</span>
          <v-icon :right="isMobile">mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ item.created_at | formatDate }}
      </template>

      <template #[`item.file`]="{ item, isMobile }">
        <div class="d-flex justify-center">
          <v-btn
            color="primary"
            elevation="0"
            rounded
            :small="isMobile"
            :icon="!isMobile"
            :loading="invoiceItemsLoading.includes(item.id)"
            @click.stop="downloadInvoice({ id: item.id, path: item.file })"
          >
            <span v-if="isMobile">Завантажити</span>
            <v-icon :right="isMobile">mdi-download-circle-outline</v-icon>
          </v-btn>
        </div>
      </template>

      <template #[`item.file_admin`]="{ item, isMobile }">
        <div class="d-flex justify-center">
          <v-btn
            v-if="item.file_admin"
            elevation="0"
            color="blue"
            dark
            rounded
            :small="isMobile"
            :icon="!isMobile"
            :loading="paymentDocsLoading.includes(item.id)"
            @click.stop="
              downloadPaymentDoc({ id: item.id, path: item.file_admin })
            "
          >
            <span v-if="isMobile">Завантажити</span>
            <v-icon :right="isMobile">mdi-download-circle-outline</v-icon>
          </v-btn>

          <v-btn
            v-else-if="canAddAdminFile(item.status)"
            elevation="0"
            color="blue"
            dark
            rounded
            :small="isMobile"
            :icon="!isMobile"
            @click.stop="
              () => {
                uploadDocDialog.opened = true;
                uploadDocDialog.orderId = item.id;
              }
            "
          >
            <span v-if="isMobile">Додати</span>
            <v-icon :right="isMobile">mdi-plus-circle-outline</v-icon>
          </v-btn>

          <v-icon v-else color="#777" size="16"> mdi-close-thick </v-icon>
        </div>
      </template>

      <template #[`item.sum`]="{ item, isMobile }">
        <div class="orders__table-sum">
          {{ item.sum }}
          <formula-description-tooltip
            :type="getTypeValue(item.type)"
            :sum="item.sum"
            :position="isMobile ? 'left' : 'top'"
          />
        </div>
      </template>

      <template #[`item.code`]="{ item }">
        <order-code v-if="item.code" :code="item.code" />

        <v-btn
          v-else-if="isUser"
          color="primary"
          icon
          @click.stop="
            () => {
              addCodeDialog.opened = true;
              addCodeDialog.type = item.type;
              addCodeDialog.orderId = item.id;
              addCodeDialog.sum = item.sum;
            }
          "
        >
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>

        <v-icon v-else color="#777" size="16"> mdi-close-thick </v-icon>
      </template>

      <template #[`item.note`]="{ item }">
        <template v-if="item.note">{{ item.note }}</template>
        <v-icon v-else color="#777" size="16"> mdi-close-thick </v-icon>
      </template>
    </v-data-table>

    <add-order-dialog v-model="addOrderDialog" @updated="fetchList(filters)" />

    <add-code-dialog
      v-model="addCodeDialog.opened"
      :type="addCodeDialog.type"
      :order-id="addCodeDialog.orderId"
      :sum="addCodeDialog.sum"
      @success="waitPaymentDialog.opened = true"
    />

    <upload-payment-doc-dialog
      v-model="uploadDocDialog.opened"
      :order-id="uploadDocDialog.orderId"
      @success="fetchList(filters)"
    />

    <wait-payment-dialog v-model="waitPaymentDialog.opened" />

    <order-instructions-dialog v-model="orderInstructionsDialog" />

    <confirm-dialog
      v-model="confirmDialog.opened"
      :title="confirmDialog.title"
      @confirm="confirmDialog.action()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddOrderDialog from "@/components/orders/AddOrderDialog";
import StatusSelect from "@/components/orders/StatusSelect";
import OrdersInstructions from "@/components/orders/OrdersInstructions.vue";
import FormulaDescriptionTooltip from "@/components/orders/FormulaDescriptionTooltip";
import AddCodeDialog from "@/components/orders/AddCodeDialog";
import UploadPaymentDocDialog from "@/components/orders/UploadPaymentDocDialog";
import OrderCode from "@/components/orders/OrderCode";
import WaitPaymentDialog from "@/components/orders/WaitPaymentDialog";
import OrderInstructionsDialog from "@/components/orders/OrderInstructionsDialog";
import ConfirmDialog from "@/components/common/ConfirmDialog";
import formatDate from "@/helpers/format-date";
import downloadFile from "@/helpers/download-file";
import isMobile from "@/mixins/is-mobile";

export default {
  mixins: [isMobile],
  filters: { formatDate },
  components: {
    AddOrderDialog,
    StatusSelect,
    OrdersInstructions,
    FormulaDescriptionTooltip,
    AddCodeDialog,
    OrderCode,
    UploadPaymentDocDialog,
    WaitPaymentDialog,
    OrderInstructionsDialog,
    ConfirmDialog,
  },
  data: () => ({
    loading: false,
    statusesLoading: false,
    invoiceItemsLoading: [],
    paymentDocsLoading: [],
    denyOrdersLoading: [],
    addOrderDialog: false,
    orderInstructionsDialog: false,
    noteSearch: "",

    addCodeDialog: {
      opened: false,
      type: "",
      orderId: 0,
      sum: 0,
    },

    uploadDocDialog: {
      opened: false,
      orderId: 0,
    },

    waitPaymentDialog: {
      opened: false,
    },

    confirmDialog: {
      opened: false,
      title: "",
      action: () => {},
    },

    filters: {
      statuses: [],
    },
  }),
  watch: {
    filters: {
      deep: true,
      handler(params) {
        this.fetchList(params);
      },
    },
  },
  computed: {
    ...mapGetters({
      isUser: "auth/isUser",
      isAdmin: "auth/isAdmin",
      isSuperAdmin: "auth/isSuperAdmin",
    }),
    headers() {
      const items = [
        { text: "ID", value: "id", sortable: false },
        { text: "Дата створення", value: "created_at" },
        { text: "Інвойс", width: 55, value: "file", sortable: false },
        { text: "Код сплати від клієнта", value: "code", sortable: false },
        {
          text: "Документ підтвердження від банку",
          value: "file_admin",
          width: 55,
          sortable: false,
          align: "center",
        },
        { text: "Тип оплати", value: "type", sortable: false },
        { text: "Сума інвойсу", value: "sum", sortable: false },
        { text: "Разом", value: "total", sortable: false },
        { text: "Статус", value: "status", sortable: false },
        {
          text: "Код примітка",
          width: 100,
          align: "center",
          value: "note",
          sortable: false,
        },
      ];

      if (this.isAdmin || this.isSuperAdmin) {
        items.splice(1, 0, { text: "", value: "deny-order", sortable: false });

        items.splice(
          2,
          0,
          { text: "Ім'я Прізвище", value: "user_name", sortable: false },
          { text: "Ел. пошта", value: "user_email", sortable: false }
        );
      }

      return items;
    },
    list() {
      return this.$store.state.orders.list;
    },
    filteredList() {
      return this.list.filter(({ note }) => {
        const noteText = note || "";
        return noteText.includes(this.noteSearch);
      });
    },
  },
  methods: {
    getTypeValue(typeName) {
      const { types } = this.$store.state.orders;
      const type = types.find(({ name }) => name === typeName);
      return type.value;
    },
    getStatusValue(statusName) {
      const { statuses } = this.$store.state.orders;
      const status = statuses.find(({ name }) => name === statusName);
      return status.value;
    },
    getTableRowClass({ status: statusName }) {
      switch (this.getStatusValue(statusName)) {
        case 1:
          return this.isUser ? "blue-row has-action" : "blue-row";
        case 2:
          return this.isUser ? "yellow-row has-action" : "yellow-row";
        case 3:
          return "green-row";
        case 4:
          return "red-row";
      }
    },
    showCurrentStep({ sum, id, type, status: statusName }) {
      if (!this.isUser) return;

      const statusValue = this.getStatusValue(statusName);

      switch (statusValue) {
        case 1:
          this.addCodeDialog.opened = true;
          this.addCodeDialog.type = type;
          this.addCodeDialog.orderId = id;
          this.addCodeDialog.sum = sum;
          break;
        case 2:
          this.waitPaymentDialog.opened = true;
      }
    },
    async fetchStatuses() {
      this.statusesLoading = true;
      await this.$store.dispatch("orders/fetchStatuses");
      this.statusesLoading = false;
    },
    async fetchList(params = {}) {
      this.loading = true;
      const requestParams = {};

      Object.keys(params).forEach((key) => {
        if (params[key] && params[key].length) {
          requestParams[key] = params[key];
        }
      });

      await this.$store.dispatch("orders/fetchList", requestParams);
      this.loading = false;
    },
    canAddAdminFile(statusName) {
      const isPermitted = this.isAdmin || this.isSuperAdmin;
      return this.isInvoicePaymentStatus(statusName) && isPermitted;
    },
    isInvoicePaymentStatus(statusName) {
      return this.getStatusValue(statusName) === 2;
    },
    async downloadInvoice({ id, path }) {
      this.invoiceItemsLoading.push(id);

      await downloadFile(path);

      this.invoiceItemsLoading = this.invoiceItemsLoading.filter(
        (item) => item !== id
      );
    },
    async downloadPaymentDoc({ id, path }) {
      this.paymentDocsLoading.push(id);

      await downloadFile(path);

      this.paymentDocsLoading = this.paymentDocsLoading.filter(
        (item) => item !== id
      );
    },
    isDenyOrderBtnVisible(status) {
      const statusValue = this.getStatusValue(status);
      return statusValue === 1 || statusValue === 2;
    },
    async denyOrder(orderId) {
      this.denyOrdersLoading.push(orderId);
      const { success } = await this.$store.dispatch(
        "orders/denyOrder",
        orderId
      );

      if (success) this.fetchList(this.filters);

      this.denyOrdersLoading = this.denyOrdersLoading.filter(
        (id) => id !== orderId
      );
    },
  },
  async created() {
    await Promise.all([
      this.fetchStatuses(),
      this.$store.dispatch("orders/fetchTypes"),
    ]);
    this.fetchList();
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.orders {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    @media (max-width: $md) {
      flex-direction: column;
      align-items: center;
    }

    &-actions {
      display: flex;
      justify-content: space-between;

      @media (max-width: $md) {
        width: 100%;
      }
    }
  }

  &__title {
    font-size: 25px;
    color: $secondary;

    @media (max-width: $md) {
      margin-bottom: 10px;
    }
  }

  &__note-search {
    max-width: 200px;
    margin-left: auto;
  }

  &__note-search,
  &__add-order-btn {
    margin-right: 10px !important;
  }

  &__steps {
    @media (max-width: $md) {
      display: none;
    }
  }

  &__table {
    overflow-y: auto;
    flex-grow: 0;
    height: 100%;

    @include scrollbar;

    tr {
      &.has-action {
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      &.red-row {
        background-color: #fbe0e2;

        &:hover {
          background-color: #fbe0e2 !important;
        }
      }

      &.green-row {
        background-color: #e4f4e5;

        &:hover {
          background-color: #e4f4e5 !important;
        }
      }

      &.yellow-row {
        background-color: #fdf9d1;

        &:hover {
          background-color: #fdf9d1 !important;
        }
      }

      &.blue-row {
        background-color: #d9edfd;

        &:hover {
          background-color: #d9edfd !important;
        }
      }
    }

    &-sum {
      max-width: 150px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .v-icon {
        margin-left: 10px;
      }
    }

    .v-data-table__mobile-row__header {
      color: $secondary;
    }

    th {
      line-height: 1.3 !important;

      &.sortable {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>

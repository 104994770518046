import store from "@/store";

const showNotification = ({ message, error }) => {
  const notification = {
    type: "success",
    text: message,
  };

  if (error) {
    notification.type = "error";
    notification.text = error;
  }

  store.dispatch("notifications/add", notification);
};

export default showNotification;

<template>
  <default-dialog v-model="opened" title="Додати код сплати" max-width="400">
    <v-form ref="add-code" @submit.prevent="onSubmit">
      <div class="add-code__type">
        <b>{{ type }}</b>
        <p v-html="typeDescription" />
      </div>
      <v-text-field
        label="Cума інвойсу"
        dense
        outlined
        readonly
        :value="sum"
        hide-details
      />

      <v-divider class="add-code__divider" />

      <v-text-field
        v-model="code"
        dense
        outlined
        :label="label"
        :rules="rules"
      />
      <v-btn
        type="submit"
        color="primary"
        elevation="0"
        block
        :loading="loading"
      >
        Зберегти
      </v-btn>
    </v-form>
  </default-dialog>
</template>

<script>
import DefaultDialog from "@/components/common/DefaultDialog";
import typesDescription from "@/constants/typesDescription";
import { isRequired, isUrl } from "@/helpers/validation-rules";

export default {
  components: { DefaultDialog },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
    sum: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    code: "",
  }),
  watch: {
    opened(val) {
      if (!val) {
        this.code = "";
        this.$refs["add-code"].resetValidation();
      }
    },
  },
  computed: {
    opened: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    types() {
      return this.$store.state.getters["orders/activeTypes"];
    },
    typeDescription() {
      const { types } = this.$store.state.orders;
      const type = types.find(({ name }) => name === this.type);

      return type ? typesDescription[type.value] : null;
    },
    isCash() {
      return this.checkCurrentTypeByNameValue(1);
    },
    isCryptoCash() {
      return this.checkCurrentTypeByNameValue(2);
    },
    isCrypto() {
      return this.checkCurrentTypeByNameValue(3);
    },
    label() {
      if (this.isCash) return "Код";
      if (this.isCrypto || this.isCryptoCash) return "Посилання на хеш";

      return "";
    },
    rules() {
      const rules = [isRequired];

      if (this.isCash) {
        rules.push(this.isValidCode);
      }

      if (this.isCrypto || this.isCryptoCash) {
        rules.push(this.isUrl);
      }

      return rules;
    },
  },
  methods: {
    isRequired,
    isUrl,
    isValidCode(v) {
      const isNumber = !isNaN(v);
      const validLength = v.length === 10;

      if (!isNumber) return "Тільки цифри";
      if (!validLength) return "Код має складатись із 10 цифр";

      return true;
    },
    checkCurrentTypeByNameValue(value) {
      const { types } = this.$store.state.orders;
      const type = types.find((item) => item.value === value);

      if (!type) return false;

      return this.type === type.name;
    },
    async onSubmit() {
      const isValid = this.$refs["add-code"].validate();

      if (!isValid) return;

      this.loading = true;

      const { success } = await this.$store.dispatch("orders/updateItem", {
        id: this.orderId,
        formData: {
          code: this.code,
        },
      });

      if (success) {
        this.opened = false;
        this.$emit("success");
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.add-code {
  &__type {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #eee;
    margin-bottom: 15px;

    b {
      font-size: 16px;
      color: #777;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      color: #777;
    }
  }

  &__divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>

<template>
  <default-dialog
    v-model="opened"
    class="wait-payment"
    title="Дякуємо за оплату !"
  >
    <div class="wait-payment__message">
      <v-icon size="40" color="#777">mdi-cash-clock</v-icon>
      <p>Очікуйте підтвердження платежу</p>
    </div>
    <v-btn color="primary" elevation="0" block @click="opened = false">
      Ок
    </v-btn>
  </default-dialog>
</template>

<script>
import DefaultDialog from "@/components/common/DefaultDialog.vue";

export default {
  components: { DefaultDialog },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    opened: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
};
</script>

<style lang="scss">
.wait-payment {
  &__message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #eee;
    font-weight: 500;
    color: #777;

    p {
      margin-top: 5px;
    }
  }
}
</style>

const handleError = ({ response }) => {
  if (response.status === 422) {
    return {
      validationErrors: response.data.errors,
    };
  }

  return response.data.message;
};

export default handleError;

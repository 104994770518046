import handleError from "@/helpers/handle-error";

const state = () => ({
  settingsDialogOpened: false,
  createAdminDialogOpened: false,
  rate: null,
});

const actions = {
  async updateUser({ commit }, formData) {
    try {
      const { data } = await this.$axios.post("user/update", formData);

      commit("auth/setUser", data, { root: true });

      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  async createAdmin(_, formData) {
    try {
      await this.$axios.post("admin/create-admin", formData);
      return { success: true };
    } catch (error) {
      return handleError(error);
    }
  },

  async fetchRate({ commit }) {
    try {
      const { data } = await this.$axios.get("percents/percent");
      commit("setRate", data.percent);
    } catch (error) {
      return error;
    }
  },
};

const mutations = {
  setSettingsDialogOpened(state, payload) {
    state.settingsDialogOpened = payload;
  },

  setCreateAdminDialogOpened(state, payload) {
    state.createAdminDialogOpened = payload;
  },

  setRate(state, payload) {
    state.rate = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

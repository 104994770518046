import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import auth from "./auth";
import profile from "./profile";
import orders from "./orders";
import notifications from "./notifications";

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    orders,
    notifications,
  },
});
